import React, { CSSProperties } from 'react';
import useCustomization from '../../hooks/useCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';
import Markdown from 'react-markdown';
import useTitleContentParser from '../hooks/useTitleContentParser';
import { useAppTranslation } from '../../i18n';

interface PersonalConsentBoxCustomizationProps {
	customizationKey: CustomizationKey;
	required?: boolean;
	onClick: () => void;
}

const PersonalConsentBoxCustomization = ({
	customizationKey,
	onClick,
	required
}: PersonalConsentBoxCustomizationProps) => {
	const customizationText = useCustomization(customizationKey);
	const parsedText = useTitleContentParser(customizationText);
	const { t } = useAppTranslation();
	return (
		<div className="column">
			<div className="column-header" style={titleStyle}>
				<h5>
					<Markdown>{parsedText && parsedText.title}</Markdown>
				</h5>
				{!required && <span>{`(${t('help.optional')})`}</span>}
			</div>
			<div className="column-text" onClick={onClick}>
				<p>
					<Markdown>{parsedText && parsedText.content}</Markdown>
				</p>
			</div>
		</div>
	);
};
export default PersonalConsentBoxCustomization;

const titleStyle: CSSProperties = {
	gap: '4px'
};
