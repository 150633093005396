import { CustomizationKey } from '../app/slices/customizations.slice';

export type CheckStatus =
	| 'PENDING'
	| 'PROCESSING'
	| 'READY'
	| 'ACCEPTED'
	| 'DISCARDED'
	| 'CANCELLED'
	| 'NO_EMAIL'
	| 'EMAIL_VALIDATION_PENDING'
	| 'NO_PHONE_NUMBER'
	| 'PHONE_NUMBER_PROVIDED'
	| 'PHONE_NUMBER_VALIDATION_PENDING'
	| 'PHONE_NUMBER_SMS_DELIVERY_ERROR';

export type CheckLanguage = 'en' | 'de';

export type AdditionalConsentTranslation = {
	shortText?: string;
	longText?: string;
};

export type AdditionalConsentRecord = {
	EN?: AdditionalConsentTranslation;
	DE?: AdditionalConsentTranslation;
	required: boolean;
};

export type AdditionalConsentNames = 'CONSENT_ADDITIONAL_1' | 'CONSENT_ADDITIONAL_2';

export type AdditionalConsents = Partial<Record<AdditionalConsentNames, AdditionalConsentRecord | undefined>>;

export const ADDITIONAL_CONSENTS_CUSTOMIZATION_MAP: Record<
	AdditionalConsentNames,
	Array<{
		key: CustomizationKey;
		field: keyof AdditionalConsentTranslation;
	}>
> = {
	CONSENT_ADDITIONAL_1: [
		{ key: 'CONSENT_ADDITIONAL_1_LONG', field: 'longText' },
		{
			key: 'CONSENT_ADDITIONAL_1_SHORT',
			field: 'shortText'
		}
	],
	CONSENT_ADDITIONAL_2: [
		{ key: 'CONSENT_ADDITIONAL_2_LONG', field: 'longText' },
		{
			key: 'CONSENT_ADDITIONAL_2_SHORT',
			field: 'shortText'
		}
	]
};
