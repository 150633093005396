import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICheckItem } from '../../types/Reports';
import { RootState } from '../store';

export enum ModalEnum {
	INFO_MODAL = 1,
	CONSENT_MODAL,
	REPORT_INFO_MODAL,
	REPORT_COMMENT_MODAL,
	REPORT_COMMENT_WITH_VALUE_MODAL,
	PRIVACY_MODAL,
	AGB_MODAL,
	IMPRESSIUM_MODAL,
	CODE_INFO_MODAL,
	CRITERIA_INFO_MODAL,
	XS2ATERMS_MODAL,
	XS2APRIVACY_MODAL
}

export type CompletedCheckType =
	| 'ACCEPTED'
	| 'DISCARDED'
	| 'ACCEPTED_RELOADED'
	| 'DISCARDED_RELOADED'
	| 'EMAIL_VALIDATION_FINISHED';

const ModalsMap: Map<ModalEnum, string> = new Map([
	[ModalEnum.AGB_MODAL, 'agbModalShown'],
	[ModalEnum.CONSENT_MODAL, 'consentModalIsShown'],
	[ModalEnum.IMPRESSIUM_MODAL, 'impressiumModalIsShown'],
	[ModalEnum.REPORT_COMMENT_MODAL, 'reportCommentModalIsShown'],
	[ModalEnum.PRIVACY_MODAL, 'privacyModalIsShown'],
	[ModalEnum.INFO_MODAL, 'infoModalIsShown'],
	[ModalEnum.REPORT_INFO_MODAL, 'reportInfoModalIsShown'],
	[ModalEnum.REPORT_COMMENT_WITH_VALUE_MODAL, 'reportCommentWithValueShown'],
	[ModalEnum.CODE_INFO_MODAL, 'codeInfoModalIsShown'],
	[ModalEnum.CRITERIA_INFO_MODAL, 'criteriaInfoModalIsShown'],
	[ModalEnum.XS2APRIVACY_MODAL, 'xs2aPrivacyModalIsShown'],
	[ModalEnum.XS2ATERMS_MODAL, 'xs2aTermsModalIsShown']
]);

export type CheckItemType = 'KONTO' | 'IN_OUT';

export type ConsentDialogType = 'CONSENT' | 'FORWARDING' | 'CONSENT_ADDITIONAL_1' | 'CONSENT_ADDITIONAL_2';

const uiSlice = createSlice({
	name: 'ui',
	initialState: {
		loading: false,
		modalTitle: '',
		reportCommentCode: '',
		reportCommentText: '',
		reportCommentValue: 0,
		reportCommentTextIDCheck: '',
		reportCommentCheckIdShown: false,
		infoModalIsShown: false,
		consentModalIsShown: false,
		reportInfoModalIsShown: false,
		reportCommentModalIsShown: false,
		reportCommentWithValueShown: false,
		consentDialogType: undefined as ConsentDialogType | undefined,
		codeInfoModalIsShown: false,
		criteriaInfoModalIsShown: false,
		selectedCheckItem: {},
		selectedCheckItemType: 'KONTO',
		privacyModalIsShown: false,
		agbModalShown: false,
		impressiumModalIsShown: false,
		xs2aTermsModalIsShown: false,
		xs2aPrivacyModalIsShown: false,
		bankProcessFinished: false,
		completedCheckType: ''
	},
	reducers: {
		toggleModal(state, action) {
			const modalType: ModalEnum = action.payload;
			const property = ModalsMap.get(modalType);
			if (!property) return state;
			const currentValue: boolean = (state as any)[property];
			// if action is closing modal, just close modal
			if (currentValue) (state as any)[property] = false;
			else {
				ModalsMap.forEach((property, key) => {
					// open modal that is wanted
					if (key === modalType) (state as any)[property] = true;
					else {
						// close all other modals
						(state as any)[property] = false;
					}
				});
			}
		},
		setStartLoadingFlag(state) {
			state.loading = true;
		},
		setEndLoadingFlag(state) {
			state.loading = false;
		},
		setModalTitle(state, action) {
			state.modalTitle = action.payload;
		},
		setReportCommentCode(state, action) {
			state.reportCommentCode = action.payload;
		},
		setReportCommentText(state, action) {
			state.reportCommentText = action.payload;
		},
		setReportCommentValue(state, action) {
			state.reportCommentValue = action.payload;
		},
		setReportCommentTextIDCheck(state, action) {
			state.reportCommentTextIDCheck = action.payload;
		},
		setReportCommentCheckIDModal(state) {
			state.reportCommentCheckIdShown = true;
		},
		setReportCommentCheckIDModalHide(state) {
			state.reportCommentCheckIdShown = false;
		},
		setConsentDialogType(state, action: PayloadAction<ConsentDialogType>) {
			state.consentDialogType = action.payload;
		},
		setBankProcessFinished(state) {
			state.bankProcessFinished = true;
		},
		setFinalStepType(state, action) {
			state.completedCheckType = action.payload;
		},
		setSelectedCheckItem(state, action: PayloadAction<{ item: ICheckItem; type: CheckItemType }>) {
			state.selectedCheckItem = action.payload.item;
			state.selectedCheckItemType = action.payload.type;
		}
	}
});

export const selectSelectedCheckItem = (state: RootState) => {
	return state.ui.selectedCheckItem;
};

export const selectCompletedCheckType = (state: RootState) => {
	return state.ui.completedCheckType as CompletedCheckType;
};

export const uiActions = uiSlice.actions;

export const { setBankProcessFinished, setFinalStepType } = uiActions;

export const handlerOpenModalReportCommentsWithValue = (
	title: string,
	code: string,
	comment?: string,
	newResult?: string
) => {
	return (dispatch: any) => {
		dispatch(uiActions.setModalTitle(code));
		dispatch(uiActions.setReportCommentCode(code));

		if (comment) dispatch(uiActions.setReportCommentText(comment));
		else dispatch(uiActions.setReportCommentText(''));

		if (newResult) dispatch(uiActions.setReportCommentValue(newResult));
		else dispatch(uiActions.setReportCommentValue(''));

		dispatch(uiActions.toggleModal(ModalEnum.REPORT_COMMENT_WITH_VALUE_MODAL));
	};
};

export const handlerOpenModalReportComments = (
	title: string,
	code: string,
	comment?: string,
	isIDCheck: boolean = false
) => {
	return (dispatch: any, getState: any) => {
		const reportCommentTextIDCheck = getState().ui.reportCommentTextIDCheck;

		dispatch(uiActions.setModalTitle(code));
		dispatch(uiActions.setReportCommentCode(code));

		if (comment) dispatch(uiActions.setReportCommentText(comment));
		else dispatch(uiActions.setReportCommentText(''));

		dispatch(uiActions.toggleModal(ModalEnum.REPORT_COMMENT_MODAL));

		if (isIDCheck) {
			dispatch(uiActions.setReportCommentCheckIDModal());
			dispatch(uiActions.setReportCommentTextIDCheck(reportCommentTextIDCheck));
		}
	};
};

export default uiSlice;
