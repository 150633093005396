import { AxiosResponseInterceptor } from './types';
import { CHECK_EXPIRED_MESSAGE, HttpHeader, HttpStatus } from '../constants/http';
import store from '../app/store';
import { selectLongTermJWT, setShortTermJWT } from '../app/slices/session.slice';
import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { TokenService } from '../services/TokenService';
import { ApiService } from './axios';
import NewRelicAgent from '../newrelic';
import { sleep } from '../utils/sleep';
import { Env } from '../constants/env';

type RetryAxiosConfig = InternalAxiosRequestConfig<any> & { isRetry?: boolean };
type GatewayAxiosConfig = InternalAxiosRequestConfig<any> & { skipGatewayCheck?: boolean };

export const AuthorizationRefreshInterceptor: AxiosResponseInterceptor = {
	onRejected: async (error) => {
		if (!isAuthorizationTokenExpired(error)) throw error;

		try {
			const longTermToken = getLongTermToken();
			const token = await fetchRefreshedShortTermToken(longTermToken);

			store.dispatch(setShortTermJWT(token));
			return await ApiService.request(error.config);
		} catch (err) {
			throw err;
		}
	}
};

const isAuthorizationTokenExpired = (error: any): boolean => {
	const response = error.response;
	return response && response.status === HttpStatus.ACCESS_FORBIDDEN;
};

const getLongTermToken = () => {
	const token = selectLongTermJWT(store.getState());
	if (token === '') throw new Error('Long term token not defined inside request authorization refresh interceptor');
	return token;
};

const fetchRefreshedShortTermToken = async (token: string) => {
	const response = await new TokenService().fetchAuthorizationToken(token);

	if (!isTokenRefreshedResponse(response)) {
		throw new Error(`Unsupported response on token refresh: ${response.status}`);
	}

	return getRefreshedToken(response);
};

const isTokenRefreshedResponse = (response: AxiosResponse): boolean => {
	return response.status === HttpStatus.RESET_CONTENT;
};

const getRefreshedToken = (response: AxiosResponse) => {
	const headers = response.headers;
	const token = headers[HttpHeader['x-check-token']];
	if (!token) throw new Error('Refresh short term token not defined on HTTP 205 Reset');
	return token;
};

export const RefreshedTokenInterceptor: AxiosResponseInterceptor = {
	onFulfilled: async (response) => {
		if (isTokenRefreshedResponse(response)) {
			const token = getRefreshedToken(response);
			store.dispatch(setShortTermJWT(token));

			const config = response.config;
			return await ApiService.request(config);
		}

		return response;
	}
};

export const GatewayErrorInterceptor: AxiosResponseInterceptor = {
	onRejected: (error: AxiosError): Promise<never> => {
		const skipGatewayCheck = (error.config as GatewayAxiosConfig | undefined)?.skipGatewayCheck || false;
		if (!skipGatewayCheck && error.response && isGatewayResponseError(error.response)) {
			return Promise.reject(Object.assign(new Error('Gateway error'), { status: error.response.status }));
		}
		return Promise.reject(error);
	}
};

const isGatewayResponseError = (response: AxiosResponse): boolean => {
	const status = response.status;
	return (
		status === HttpStatus.BAD_GATEWAY ||
		status === HttpStatus.SERVICE_UNAVAILABLE ||
		status === HttpStatus.GATEWAY_TIMEOUT
	);
};

interface RetryOptions {
	skipStatusCodes: Array<number>;
}

export const attachRetryInterceptor = (axiosInstance: AxiosInstance, options?: RetryOptions) => {
	const maxRetries = Number(process.env[Env.REACT_APP_MAX_REQUEST_RETRIES]) || 3;
	const delay = Number(process.env[Env.REACT_APP_REQUEST_RETRY_DELAY]) || 500;

	const onRejected = async (error: AxiosError) => {
		const isRetry = (error.config as RetryAxiosConfig | undefined)?.isRetry || false;
		if (
			isRetry ||
			!error.config ||
			(error.response?.status &&
				(options?.skipStatusCodes.includes(error.response?.status) ||
					(error.response?.data as any)?.type === CHECK_EXPIRED_MESSAGE))
		) {
			throw error;
		}
		let retries = 0;
		const start = Date.now();
		while (retries < maxRetries) {
			retries++;
			try {
				await sleep(delay);
				const response = await axiosInstance.request({
					...error.config,
					isRetry: true,
					skipGatewayCheck: true
				} as GatewayAxiosConfig & RetryAxiosConfig);
				NewRelicAgent.log(
					`Retry: ${retries};  ${error.config?.url || error.config?.baseURL || 'UNKNOWN URL'}`,
					{
						level: 'TRACE',
						customAttributes: {
							numberOfRetry: retries,
							requestDuration: Date.now() - start,
							url: error.config?.url || error.config?.baseURL,
							method: error.config?.method,
							statusCode: response.status
						}
					}
				);
				return response;
			} catch (innerError) {
				if (!axios.isAxiosError(innerError) || !innerError.config) {
					continue;
				}
				error = innerError;
				NewRelicAgent.log(
					`Retry: ${retries};  ${innerError.config.url || innerError.config.baseURL || 'UNKNOWN URL'}`,
					{
						level: 'TRACE',
						customAttributes: {
							numberOfRetry: retries,
							requestDuration: Date.now() - start,
							url: innerError.config.url || innerError.config.baseURL,
							method: innerError.config.method,
							statusCode: innerError.status || innerError.response?.status,
							body: innerError.response?.data
						}
					}
				);
			}
		}
		throw error;
	};
	return axiosInstance.interceptors.response.use(null, onRejected);
};
