import React, { FC, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useAppTranslation } from '../../../i18n';
import { ConsentBox } from './ConsentBox';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
	personaActions,
	PersonaInformation,
	selectConsentAdditional1Data,
	selectConsentAdditional2Data,
	selectConsentCollectingData,
	selectConsetForwardingData,
	selectPersonaInformation
} from '../../../app/slices/persona.slice';
import { ConsentDialogType, ModalEnum, uiActions } from '../../../app/slices/ui.slice';
import { CustomizationKey } from '../../../app/slices/customizations.slice';
import { Header } from './Header';
import { SubmitButton } from '../shared/SubmitButton';
import ConsentText from '../ConsentText';
import { putPersonaData } from '../../../app/thunks/put-persona-data';
import moment from 'moment';
import { selectDeactivatedConsentForwardingAndResults } from '../../../app/slices/flags.slice';
import useCustomization from '../../../hooks/useCustomization';
import jwtDecode from 'jwt-decode';
import { selectAdditionalConsents } from '../../../app/slices/report.slice';
import { AdditionalConsents } from '../../../types/check';

const CONSENT_COLLECTION_KEY: CustomizationKey = 'CONSENT_COLLECTION_AND_PROCESSING_SHORT';
const CONSENT_FORWARDING_KEY: CustomizationKey = 'CONSENT_FORWARDING_AND_RESULTS_SHORT';

export const PersonaConsentStep: FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useAppTranslation();

	const persona = useAppSelector(selectPersonaInformation);
	const isCollectingChecked = useAppSelector(selectConsentCollectingData);
	const isForwardingChecked = useAppSelector(selectConsetForwardingData);
	const isConsentAdditional1Checked = useAppSelector(selectConsentAdditional1Data);
	const isConsentAdditional2Checked = useAppSelector(selectConsentAdditional2Data);

	const deactivatedConsent = useAppSelector(selectDeactivatedConsentForwardingAndResults);
	const additionalConsents = useAppSelector(selectAdditionalConsents);
	const additionalConsentsParsed = useMemo(() => {
		return additionalConsents ? jwtDecode<AdditionalConsents>(additionalConsents) : undefined;
	}, [additionalConsents]);

	const consentAdditional1LongText = useCustomization('CONSENT_ADDITIONAL_1_LONG');
	const consentAdditional2LongText = useCustomization('CONSENT_ADDITIONAL_2_LONG');

	const [submitting, setSubmitting] = useState<boolean>(false);

	const openModal = (modalType: ConsentDialogType) => (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		dispatch(uiActions.setConsentDialogType(modalType));
		dispatch(uiActions.toggleModal(ModalEnum.CONSENT_MODAL));
	};

	const onCollectionClick = () => {
		dispatch(personaActions.setPersonaCollectingDataConsent(!isCollectingChecked));
	};

	const onForwardingClick = () => {
		dispatch(personaActions.setPersonaForwardingDataConsent(!isForwardingChecked));
	};
	const onConsentAdditional1Click = () => {
		dispatch(personaActions.toggleConsentAdditional1());
	};

	const onConsentAdditional2Click = () => {
		dispatch(personaActions.toggleConsentAdditional2());
	};

	const handleSubmit = async () => {
		try {
			setSubmitting(true);
			const data = createPersonaData(persona);
			await dispatch(putPersonaData(data));
		} finally {
			setSubmitting(false);
		}
	};

	const isSubmitDisabled =
		!isCollectingChecked ||
		(!isForwardingChecked && !deactivatedConsent) ||
		(additionalConsentsParsed?.CONSENT_ADDITIONAL_1?.required && !isConsentAdditional1Checked) ||
		(additionalConsentsParsed?.CONSENT_ADDITIONAL_2?.required && !isConsentAdditional2Checked) ||
		false;
	return (
		<Box id="persona-consent-step">
			<Header />
			<Box className="personal-consent">
				<div className="title">
					<h3>{t('personal-consent-page.consents')}</h3>
				</div>
				<ConsentBox
					id="collecting"
					required
					onClick={onCollectionClick}
					onButtonClick={openModal('CONSENT')}
					customizationKey={CONSENT_COLLECTION_KEY}
					checked={isCollectingChecked}
				/>
				{!deactivatedConsent && (
					<ConsentBox
						id="forwarding"
						required
						onClick={onForwardingClick}
						onButtonClick={openModal('FORWARDING')}
						customizationKey={CONSENT_FORWARDING_KEY}
						checked={isForwardingChecked}
					/>
				)}
				{additionalConsentsParsed?.CONSENT_ADDITIONAL_1 && (
					<div id="consentAdditional1Container">
						<ConsentBox
							id="consentAdditional1"
							required={additionalConsentsParsed.CONSENT_ADDITIONAL_1.required}
							hideDetailsButton={!consentAdditional1LongText}
							onClick={onConsentAdditional1Click}
							onButtonClick={openModal('CONSENT_ADDITIONAL_1')}
							customizationKey="CONSENT_ADDITIONAL_1_SHORT"
							checked={isConsentAdditional1Checked}
						/>
					</div>
				)}
				{additionalConsentsParsed?.CONSENT_ADDITIONAL_2 && (
					<div id="consentAdditional2Container">
						<ConsentBox
							id="consentAdditional2"
							required={additionalConsentsParsed.CONSENT_ADDITIONAL_2.required}
							hideDetailsButton={!consentAdditional2LongText}
							onClick={onConsentAdditional2Click}
							onButtonClick={openModal('CONSENT_ADDITIONAL_2')}
							customizationKey="CONSENT_ADDITIONAL_2_SHORT"
							checked={isConsentAdditional2Checked}
						/>
					</div>
				)}
			</Box>
			<SubmitButton
				disabled={isSubmitDisabled}
				submitting={submitting}
				text={t('button.start')}
				onSubmit={handleSubmit}
			/>
			<ConsentText />
		</Box>
	);
};

const createPersonaData = (persona: PersonaInformation): PersonaInformation => {
	const dateOfBirth = moment(persona.dateOfBirth, 'DD.MM.YYYY').format('YYYY-MM-DD');
	return {
		...persona,
		dateOfBirth
	};
};
