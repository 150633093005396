import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type FlagsState = {
	flags: Record<string, boolean>;
};

const initialState: FlagsState = {
	flags: {}
};

export enum FlagsEnum {
	SHOW_RESULT = 'SHOW_RESULTS',
	ASSESSMENT_USER_INPUT = 'ASSESSMENT_USER_INPUT',
	EDIT_CHECK_DATA = 'EDIT_CHECK_DATA',
	DEACTIVATE_CONSENT_FORWARDING_AND_RESULTS = 'DEACTIVATE_CONSENT_FORWARDING_AND_RESULTS',
	CONSENT_ADDITIONAL_1 = 'CONSENT_ADDITIONAL_1',
	CONSENT_ADDITIONAL_2 = 'CONSENT_ADDITIONAL_2'
}

const flagsSlice = createSlice({
	name: 'flagsPackage',
	initialState,
	reducers: {
		setFlags(state, action: PayloadAction<Array<string>>) {
			const flags = action.payload;
			for (const flag of flags) {
				state.flags[flag] = true;
			}
		},
		resetFlags(state, action: PayloadAction<void>) {
			state.flags = {};
		}
	}
});

export const selectFlag = (state: RootState, flag: FlagsEnum): boolean => {
	const isPresent = state.flagsSlice.flags[flag];
	// if isPresent is undefined, there is no specified flag, so return false (edge case)
	if (isPresent === undefined) return false;
	return isPresent;
};

export const selectShowResults = (state: RootState) => selectFlag(state, FlagsEnum.SHOW_RESULT);

export const selectConsentAdditional1 = (state: RootState) => selectFlag(state, FlagsEnum.CONSENT_ADDITIONAL_1);
export const selectConsentAdditional2 = (state: RootState) => selectFlag(state, FlagsEnum.CONSENT_ADDITIONAL_2);

export const selectDeactivatedConsentForwardingAndResults = (state: RootState) =>
	selectFlag(state, FlagsEnum.DEACTIVATE_CONSENT_FORWARDING_AND_RESULTS);

export const flagsActions = flagsSlice.actions;

export const { setFlags, resetFlags } = flagsActions;

export default flagsSlice;
