import useCustomization from '../../hooks/useCustomization';
import LegalDocsMarkdown from '../custom/LegalDocsMarkdown';
import React from 'react';
import { CustomizationKey } from '../../app/slices/customizations.slice';
import { useAppSelector } from '../../app/hooks';

const CONSENT_COLLECTION_KEY: CustomizationKey = 'CONSENT_COLLECTION_AND_PROCESSING_LONG';
const CONSENT_FORWARDING_KEY: CustomizationKey = 'CONSENT_FORWARDING_AND_RESULTS_LONG';

const ConsentModalCustomization = () => {
	const consentDialogType = useAppSelector((state) => state.ui.consentDialogType);

	const customizationsConsentCollectionDataLong = useCustomization(CONSENT_COLLECTION_KEY);
	const customizationsConsentForwardingDataLong = useCustomization(CONSENT_FORWARDING_KEY);
	const additionalConsent1 = useCustomization('CONSENT_ADDITIONAL_1_LONG');
	const additionalConsent2 = useCustomization('CONSENT_ADDITIONAL_2_LONG');

	return (
		<React.Fragment>
			{consentDialogType === 'CONSENT' && customizationsConsentCollectionDataLong && (
				<LegalDocsMarkdown content={customizationsConsentCollectionDataLong} />
			)}
			{consentDialogType === 'FORWARDING' && customizationsConsentForwardingDataLong && (
				<LegalDocsMarkdown content={customizationsConsentForwardingDataLong} />
			)}
			{consentDialogType === 'CONSENT_ADDITIONAL_1' && additionalConsent1 && (
				<LegalDocsMarkdown content={additionalConsent1} />
			)}
			{consentDialogType === 'CONSENT_ADDITIONAL_2' && additionalConsent2 && (
				<LegalDocsMarkdown content={additionalConsent2} />
			)}
		</React.Fragment>
	);
};

export default ConsentModalCustomization;
