import { useMemo } from 'react';

interface ParsedContent {
	title: string;
	content: string;
}

const useTitleContentParser = (children: string | null | undefined): ParsedContent | null => {
	return useMemo(() => {
		if (!children) return null;
		const [title, ...contentArray] = children.split('\n').map((line) => line.trim());
		const content = contentArray.join('\n');
		return { title, content };
	}, [children]);
};

export default useTitleContentParser;
