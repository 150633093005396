import React, { useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import calendar from '../../assets/images/icons/calendar.svg';
import { useTheme } from '@mui/material';
import { useAppTranslation } from '../../i18n';
import { AppEnv } from '../../constants/env';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de-at';

type CustomDatePickerProps = {
	initValue?: Date | null;
	placeholder: string;
	onChange: (param: { isValid: boolean; date: Date | null }) => void;
	id: string;
	disabled: boolean;
	maxDate?: any;
};

const isDev = process.env['REACT_APP_ENV'] === AppEnv.DEV;

const CustomDatePicker = ({
	placeholder,
	onChange,
	id,
	initValue = null,
	disabled = false,
	maxDate
}: CustomDatePickerProps) => {
	const {
		t,
		i18n: { language }
	} = useAppTranslation();

	const [value, setValue] = React.useState<Date | null>(initValue);
	const [error, setError] = React.useState<boolean>(false);
	const theme = useTheme();

	const locale = getAdapterLocale(language);
	const toolbarFormat = getToolbarFormat(language);

	useEffect(() => {
		moment.locale(locale);
	}, [locale]);

	const handleChange = (value: Date | null) => {
		setValue(value);
		//@ts-ignore
		if (value && value._isValid && moment(value).diff(maxDate, 'days') <= 0) {
			if (error) setError(false);
			onChange({
				isValid: true,
				date: value
			});
		} else {
			setError(true);
			onChange({ isValid: false, date: value });
		}
	};

	const errorStyle = error
		? {
				border: '1px solid red !important'
		  }
		: {};

	const style = {
		width: '100%',
		marginTop: '20px',
		input: { color: `${error ? '#FF0000' : 'black'}` },
		'&:focus-within': {
			border: `1px solid ${theme.palette.primary.main} !important`
		},
		...errorStyle
	};

	return (
		<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
			<DatePicker
				defaultCalendarMonth={maxDate}
				maxDate={maxDate}
				disabled={disabled}
				mask="__.__.____"
				desktopModeMediaQuery={isDev ? 'not (pointer: coarse)' : undefined}
				label={placeholder}
				value={value}
				onChange={handleChange}
				inputFormat={'DD.MM.YYYY'}
				components={{
					OpenPickerIcon: () => <img src={calendar} className="calendar-open-image" alt="calendar-image" />
				}}
				toolbarFormat={toolbarFormat}
				renderInput={(params) => (
					<TextField
						id={id}
						{...params}
						variant="filled"
						helperText={null}
						sx={style}
						inputProps={{
							...params.inputProps,
							placeholder: t('inputs.date-input-format')
						}}
						InputLabelProps={{
							style: {
								color: `${error ? '#FF0000' : '#707070'}`,
								fontSize: '18px'
							}
						}}
						className={`txt-field-date-picker ${error ? 'Mui-error' : ''}`}
						placeholder={placeholder}
					/>
				)}
			/>
		</LocalizationProvider>
	);
};

export default React.memo(CustomDatePicker);

type AdapterLocale = 'de-at' | 'en' | 'en-gb';

const getAdapterLocale = (language: string): AdapterLocale => {
	return language === 'de' ? 'de-at' : 'en-gb';
};

const getToolbarFormat = (language: string) => {
	return language === 'de' ? 'dd, D. MMM' : 'ddd, MMM D';
};
